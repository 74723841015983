<template>
    <div class="card overflow-hidden" v-if="dusun.length > 0">
        <div class="card-heading bg-light">
            <h3 class="card-title text-uppercase">Data Dusun</h3>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table id="tb-dusun" class="table table-striped table-bordered dt-responsive nowrap"
                    style="border-collapse: collapse; border-spacing: 0; width: 100%;" data-turbolinks="false">
                    <thead>
                        <tr>
                            <th width="50%">Kode_Dusun</th>
                            <th width="50%">Dusun</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="dsn in dusun" :key="dsn.Kode_Dusun">
                            <td>{{ dsn.Kode_Dusun }}</td>
                            <td>{{ dsn.Dusun }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { useToast } from "vue-toastification";

export default {

    name: 'Dusun',
    props: ['pkmKode', 'kodeKab'],
    setup() {
        const toast = useToast();
        return {
            toast
        }
    },
    data() {
        return {
            dusun: [],
        }
    },
    methods: {
        async getDusun() {
            await axios.get(`dasar/dusun/` + this.kodeKab)
            .then(response => {
                this.dusun = response.data.content.dusun;
            })
            .catch(e => {
                this.toast.error(e.response.data.errors.message.description);
            })
        }
    },
    watch: {
        kodeKab() {
            this.getDusun();
        }
    },
    created() {
        if (typeof this.kodeKab !== 'undefined' && this.kodeKab !== '') {
            this.getDusun();
        }
    }
};
</script>

<style lang="css" scoped>
</style>
