<template>
    <div class="col-lg-10">
        <div class="row mb-4">
            <div class="col-md-3">
                <label class="control-label">Filter Puskesmas</label>
                <Select2 v-model="pkmKode" :options="puskesmas" @select="selectPkm($event)" />
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="card overflow-hidden">
                    <div class="card-heading bg-light">
                        <h3 class="card-title text-uppercase">Data Puskesmas</h3>
                    </div>
                    <div class="card-body">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link show active" @click="showComponent('Dusun')" id="profile-tab"
                                    data-toggle="tab" href="#kelurahan" role="tab" aria-controls="profile"
                                    aria-selected="true">
                                    <span class="d-block d-sm-none"><i class="fas fa-user"></i></span>
                                    <span class="d-none d-sm-block">Kelurahan</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" @click="showComponent('')" id="profile-tab" data-toggle="tab"
                                    href="#profile" role="tab" aria-controls="profile" aria-selected="true">
                                    <span class="d-block d-sm-none"><i class="fas fa-user"></i></span>
                                    <span class="d-none d-sm-block">Petugas Medis</span>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" @click="showComponent('')" id="message-tab" data-toggle="tab"
                                    href="#message" role="tab" aria-controls="message" aria-selected="false">
                                    <span class="d-block d-sm-none"><i class="far fa-envelope"></i></span>
                                    <span class="d-none d-sm-block">Posyandu</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane show active" id="kelurahan" role="tabpanel" aria-labelledby="home-tab">
                                <div class="table-responsive">
                                    <table id="tb-kelurahan"
                                        class="table table-striped table-bordered table-hover dt-responsive nowrap"
                                        style="cursor:pointer; border-collapse: collapse; border-spacing: 0; width: 100%;">
                                        <thead>
                                            <tr>
                                                <th width="12%">KodeKab</th>
                                                <th width="20%">Desa</th>
                                                <th width="15%">Kode_Petugas</th>
                                                <th width="40%">Bidan</th>
                                                <th width="13%">Telepon</th>
                                            </tr>
                                        </thead>
                                        <tbody></tbody>
                                    </table>
                                </div>
                                <component :is="component" :pkmKode="pkmKode" :kodeKab="kodeKelurahan"></component>
                            </div>
                            <div class="tab-pane show" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <div class="table-responsive">
                                    <table id="tb-petugas"
                                        class="table table-striped table-bordered dt-responsive nowrap"
                                        style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                                        <thead>
                                            <tr>
                                                <th width="25%">NIP</th>
                                                <th width="5%">Kode</th>
                                                <th width="40%">Nama</th>
                                                <th width="20%">Telepon</th>
                                                <th width="10%">Jenis</th>
                                            </tr>
                                        </thead>
                                        <tbody></tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="tab-pane" id="message" role="tabpanel" aria-labelledby="message-tab">
                                <div class="table-responsive">
                                    <table id="tb-posyandu"
                                        class="table table-striped table-bordered dt-responsive nowrap"
                                        style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                                        <thead>
                                            <tr>
                                                <th width="10%">Kode_Posyandu</th>
                                                <th width="20%">Posyandu</th>
                                                <th width="20%">Desa</th>
                                                <th width="20%">Dusun</th>
                                                <th width="30%">Nama</th>
                                            </tr>
                                        </thead>
                                        <tbody></tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Select2 from 'vue3-select2-component';
import { useToast } from "vue-toastification";
import Dusun from './Dusun.vue';

export default {
    name: 'PuskesmasComponent',
    components: {
        Select2,
        Dusun
    },
    setup() {
        const toast = useToast();
        return {
            toast
        }
    },
    data() {
        return {
            puskesmas: [],
            pkmKode: '',
            kelurahan: [],
            posyandu: [],
            petugas: [],
            component: null,
            kodeKelurahan: ''
        };
    },
    methods: {
        async getPuskesmas() {
            await axios.get(`dasar/puskesmas`)
                .then(response => {
                    this.puskesmas = response.data.content.puskesmas.map(pkm => (
                        { id: pkm.Kode, text: pkm.Puskesmas.toUpperCase() }
                    ));

                    this.pkmKode = this.puskesmas[0].id;
                    this.selectPkm({ id: this.pkmKode });
                })
                .catch(e => {
                    this.toast.error(e.response.data.errors.message.description);
                })
        },
        async initTablePetugas() {
            $('#tb-petugas').DataTable({
                processing: true,
                responsive: true,
                orders: [],
                data: this.petugas,
                columns: [
                    { data: "NIP" },
                    { data: "Kode_Petugas" },
                    { data: "Nama_Petugas" },
                    { data: "Telepon" },
                    { data: "Jenis" }
                ],
                language: {
                    "lengthMenu": "Menampilkan _MENU_ data per halaman",
                    "zeroRecords": "Belum ada data untuk saat ini",
                    "info": "Menampilkan halaman _PAGE_ dari _PAGES_",
                    "infoEmpty": "Belum ada data",
                    "infoFiltered": "(Filter dari _MAX_ total data)",
                    "paginate": {
                        "next": "Selanjutnya",
                        "previous": "Sebelumnya"
                    },
                    "search": "Cari data : "
                },
            });
        },
        async initTableKelurahan() {
            var app = this;
            var tb_kelurahan = $('#tb-kelurahan').DataTable({
                processing: true,
                responsive: true,
                keys: true,
                scrollY: '200px',
                orders: [],
                data: this.kelurahan,
                columns: [
                    { data: "KodeKab" },
                    { data: "Desa" },
                    { data: "Kode_Petugas" },
                    { data: "Bidan" },
                    { data: "Telepon" }
                ],
                language: {
                    "lengthMenu": "Menampilkan _MENU_ data per halaman",
                    "zeroRecords": "Belum ada data untuk saat ini",
                    "info": "Halaman _PAGE_ dari _PAGES_",
                    "infoEmpty": "Belum ada data",
                    "infoFiltered": "(Filter dari _MAX_ total data)",
                    "paginate": {
                        "next": "Selanjutnya",
                        "previous": "Sebelumnya"
                    },
                    "search": "Cari data : "
                },
                initComplete: function () {
                    $('#tb-kelurahan tbody tr').on('click', function () {
                        var data = tb_kelurahan.row(this).data();
                        tb_kelurahan.$('tr.selected').removeClass('selected');
                        $(this).closest('tr').addClass('selected');
                        app.kodeKelurahan = data.KodeKab;
                        app.showComponent('Dusun');
                    });
                }
            });
        },
        async initTablePosyandu() {
            $('#tb-posyandu').DataTable({
                processing: true,
                responsive: true,
                orders: [],
                data: this.posyandu,
                columns: [
                    { data: "Kode_Posyandu" },
                    { data: "Posyandu" },
                    { data: "Desa" },
                    { data: "Dusun" },
                    { data: "bidan" }
                ],
                language: {
                    "lengthMenu": "Menampilkan _MENU_ data per halaman",
                    "zeroRecords": "Belum ada data untuk saat ini",
                    "info": "Menampilkan halaman _PAGE_ dari _PAGES_",
                    "infoEmpty": "Belum ada data",
                    "infoFiltered": "(Filter dari _MAX_ total data)",
                    "paginate": {
                        "next": "Selanjutnya",
                        "previous": "Sebelumnya"
                    },
                    "search": "Cari data : "
                },
            });
        },
        async selectPkm(event) {
            await axios.get(`dasar/puskesmas/` + event.id)
                .then(response => {
                    this.pkmKode = event.id;
                    this.kelurahan = response.data.content.kelurahan;
                    this.posyandu = response.data.content.posyandu;
                    this.petugas = response.data.content.petugas_medis;
                })
                .catch(e => {
                    this.toast.error(e.response.data.errors.message.description);
                })
        },
        showComponent(comp) {
            this.component = comp;
        }
    },
    watch: {
        petugas() {
            $('#tb-petugas').DataTable().destroy();
            this.initTablePetugas();
        },
        kelurahan() {
            $('#tb-kelurahan').DataTable().destroy();
            this.initTableKelurahan();
        },
        posyandu() {
            $('#tb-posyandu').DataTable().destroy();
            this.initTablePosyandu();
        },
        $route(to, from) {
            $('#tb-petugas').DataTable().clear().draw();
            $('#tb-petugas').DataTable().destroy();
            $('#tb-kelurahan').DataTable().destroy().draw();
            $('#tb-kelurahan').DataTable().destroy();
            $('#tb-posyandu').DataTable().destroy().draw();
            $('#tb-posyandu').DataTable().destroy();
        }
    },
    created() {
        this.initTablePetugas();
        this.initTableKelurahan();
        this.initTablePosyandu();
        this.getPuskesmas();
    }
};
</script>
